import React from 'react';

import 'natura11y/src/scss/natura11y.scss';

import './src/scss/theme.scss';

import Layout from './src/components/ui/Layout';

export const wrapPageElement = ({ element, props }) => {
	return <Layout {...props}>{element}</Layout>;
};