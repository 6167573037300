import React, { createContext, useState, useEffect } from 'react';

const ThemeContext = createContext('os');

export const ThemeProvider = ({ children }) => {

	const [themeType, setThemeType] = useState('os');

	const setTheme = (theme) => {
		localStorage.setItem('preferred-theme', theme);
		setThemeType(theme);
	};

	const handleOsTheme = () => {
		setTheme('os');
	};

	const handleDarkTheme = () => {
		setTheme('dark');
	};

	const handleLightTheme = () => {
		setTheme('light');
	};

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const storedTheme = localStorage.getItem('preferred-theme') || 'os';
			setThemeType(storedTheme);
		}
	}, []);

	useEffect(() => {
		const htmlEl = document.querySelector('html');
		
		let themeClass;

		switch (themeType) {
			case 'os':
				themeClass = 'theme-canvas--prefers';
				break;
			case 'dark':
				themeClass = 'theme-dark';
				break;
			case 'light':
				themeClass = 'theme-canvas';
				break;
			default:
			// do nothing
		}

		htmlEl.setAttribute('class', themeClass);
	}, [themeType]);

	const contextValue = {
		themeType,
		handleOsTheme,
		handleDarkTheme,
		handleLightTheme,
	};

	return (
		<ThemeContext.Provider value={contextValue}>
			{children}
		</ThemeContext.Provider>
	);
};

export default ThemeContext;