import React, { Fragment } from 'react';

import classNames from 'classnames';

const Icon = (props) => {
    const {
        iconHandle = 'home',
        screenReaderText = null, 
        utilities = null,
        handleClick = null
    } = props;

    const componentClasses = classNames(
        'icon',
        [`icon-${iconHandle}`],
        {
            [`${utilities}`] : utilities !== null
        }
    );

    const spanStyles = {        
        'display': 'flex',
        'pointer-events': 'auto',
    };

    return (
        <Fragment>
            <span
                className={componentClasses} 
                aria-hidden='true'
                role={handleClick ? 'button' : undefined}
                style={handleClick ? spanStyles : null}
                onClick={handleClick || null}
            >
            </span>
            {screenReaderText !== null && (
            <span className='screen-reader-only'>   
                {screenReaderText}
            </span>
            )}
        </Fragment>
    );
};

export default Icon;
