exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-examples-js": () => import("./../../../src/pages/examples.js" /* webpackChunkName: "component---src-pages-examples-js" */),
  "component---src-pages-icons-js": () => import("./../../../src/pages/icons.js" /* webpackChunkName: "component---src-pages-icons-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-accent-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/accent.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-accent-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-accessibility-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/accessibility.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-accessibility-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-accordion-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/accordion.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-accordion-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-alert-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/alert.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-alert-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-article-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/article.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-article-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-aspect-ratio-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/aspect-ratio.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-aspect-ratio-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-backdrop-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/backdrop.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-backdrop-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-border-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/border.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-border-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-breakpoint-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/breakpoint.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-breakpoint-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-button-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/button.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-button-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-card-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/card.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-card-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-collapse-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/collapse.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-collapse-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-color-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/color.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-color-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-container-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/container.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-container-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-customization-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/customization.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-customization-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-display-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/display.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-display-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-figma-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/figma.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-figma-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-flex-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/flex.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-flex-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-form-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/form.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-form-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-get-started-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/get-started.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-get-started-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-grid-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/grid.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-grid-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-icon-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/icon.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-icon-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-layer-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/layer.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-layer-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-lightbox-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/lightbox.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-lightbox-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-link-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/link.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-link-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-modal-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/modal.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-modal-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-navigation-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/navigation.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-navigation-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-opacity-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/opacity.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-opacity-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-overflow-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/overflow.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-overflow-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-position-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/position.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-position-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-react-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/react.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-react-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-rtl-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/rtl.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-rtl-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-shadow-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/shadow.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-shadow-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-sizing-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/sizing.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-sizing-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-spacing-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/spacing.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-spacing-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-tab-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/tab.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-tab-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-table-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/table.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-table-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-typography-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/typography.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-typography-mdx" */),
  "component---src-templates-docs-jsx-content-file-path-src-content-docs-z-index-mdx": () => import("./../../../src/templates/docs.jsx?__contentFilePath=/Users/carlandjohnnieavidano/Sites/natura11y-docs/src/content/docs/z-index.mdx" /* webpackChunkName: "component---src-templates-docs-jsx-content-file-path-src-content-docs-z-index-mdx" */)
}

